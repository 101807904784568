import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import useSWR, { ConfigInterface } from "swr";
import omiAbi from "../abis/omiAbi.json";
import { addresses } from "../utils/chainData";

export const abiMap = new Map([[addresses.omiToken, omiAbi]]);

class ABIError extends Error {}

class ABINotFound extends Error {}

const fetcher =
  (library: ethers.providers.Web3Provider, ABIs?: Map<string, any>) =>
  (
    // @ts-ignore
    ...args
  ) => {
    const [arg1, arg2, ...params] = args.slice(1);
    // it's a contract
    if (ethers.utils.isAddress(arg1)) {
      if (!ABIs) throw new ABIError(`ABI repo not found`);
      if (!ABIs.get) throw new ABIError(`ABI repo isn't a Map`);
      const address = arg1;
      const method = arg2;
      const abi = ABIs.get(address);
      if (!abi) throw new ABINotFound(`ABI not found for ${address}`);
      const contract = new ethers.Contract(address, abi, library);
      const _params = params;
      if (method === "queryFilter") {
        _params[0] = JSON.parse(params[0]);
      }
      return contract[method](..._params);
    }

    // it's a eth call
    const method = arg1;
    // @ts-ignore
    return library[method](arg2, ...params);
  };

export const useWeb3SWR = <R = any, E = any>(
  args: [provider: "ethereum", ...args: any],
  options?: ConfigInterface<R, E>
) => {
  const provider = args[0];
  const { library } = useWeb3React<ethers.providers.Web3Provider>(provider);
  if (!library) {
    throw new Error(`${provider} not configured`);
  }
  return useSWR<R, E>(args, {
    fetcher: fetcher(library, abiMap),
    revalidateOnFocus: false,
    ...options,
  });
};
