import { ImmutableXClient } from "@imtbl/imx-sdk";
import { useState, useEffect } from "react";
import { addresses, imxApiUrl } from "../utils/chainData";

export const useImx = () => {
  const [imxClient, setImxClient] = useState<ImmutableXClient | undefined>(
    undefined
  );
  const [reserveBalance, setReserveBalance] = useState<string | undefined>();
  const [vaultBalance, setVaultBalance] = useState<string | undefined>();
  const [burnBalance, setBurnBalance] = useState<string | undefined>();

  useEffect(() => {
    const init = async () => {
      if (imxClient) {
        return imxClient;
      }
      const client = await ImmutableXClient.build({
        publicApiUrl: imxApiUrl,
      });
      setImxClient(client);
      return client;
    };
    const fetchReserveBalance = async () => {
      let client = await init();
      const { balance } = await client.getBalance({
        user: addresses.imx.veveReserve,
        tokenAddress: addresses.omiToken,
      });
      return balance.toString();
    };
    const fetchVaultBalance = async () => {
      let client = await init();
      const { balance } = await client.getBalance({
        user: addresses.imx.veveVault,
        tokenAddress: addresses.omiToken,
      });
      return balance.toString();
    };
    const fetchBurnBalance = async () => {
      let client = await init();
      const { balance } = await client.getBalance({
        user: addresses.imx.veveBurn,
        tokenAddress: addresses.omiToken,
      });
      return balance.toString();
    };
    Promise.all([
      fetchReserveBalance(),
      fetchVaultBalance(),
      fetchBurnBalance(),
    ]).then(([reserveBalance, vaultBalance, burnBalance]) => {
      setReserveBalance(reserveBalance);
      setVaultBalance(vaultBalance);
      setBurnBalance(burnBalance);
    });
  }, [imxClient, reserveBalance, vaultBalance, burnBalance]);

  return {
    reserveBalance,
    vaultBalance,
    burnBalance,
  };
};
