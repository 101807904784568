import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { SimpleERC20TokenFactory } from "../abis/SimpleERC20TokenFactory";
import { addresses } from "../utils/chainData";

export const useEthereumBurns = () => {
  const [totalBurned, setTotalBurned] = useState<string | undefined>();

  const { library } = useWeb3React<ethers.providers.Web3Provider>("ethereum");
  if (!library) {
    throw new Error("Library not configured");
  }

  useEffect(() => {
    const omiContract = SimpleERC20TokenFactory.connect(
      addresses.omiToken,
      library
    );
    // Token burns trigger a Transfer event
    const fetchOmiTransfers = async () =>
      omiContract.queryFilter(
        omiContract.filters.Transfer(
          addresses.imx.veveBurn,
          ethers.constants.AddressZero,
          null
        ),
        14085567
      );

    fetchOmiTransfers().then((events) => {
      let totalBurned = new BigNumber("0");
      for (const {
        args: { value },
      } of events) {
        totalBurned = totalBurned.plus(
          ethers.utils.formatEther(value.toString()).toString()
        );
      }
      setTotalBurned(totalBurned.toString());
    });
  }, [library]);

  return totalBurned;
};
