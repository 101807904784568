export const addresses = {
  omiToken: "0xeD35af169aF46a02eE13b9d79Eb57d6D68C1749e",
  ethereum: {
    veveReserve: "0x00590aeAd07325386Fbe34bD27cb250345563940",
    veveVault: "0xe4404502AC45696Dc3d384C9D16677c8b4262d39",
    veveBurn: "0xbBDA162f1E3EC2D4D9D99cafd0c14B03EC4E78d3",
  },
  imx: {
    veveReserve: "0xd9494D749eD554B2D2faAB1a8e20d2b566410F00",
    veveVault: "0x17656848E63cb846D93E629C710f6B0cc30A89dc",
    veveBurn: "0xbBDA162f1E3EC2D4D9D99cafd0c14B03EC4E78d3",
  },
};

export const gochainExplorerUrl = "https://explorer.gochain.io/addr/";

export const ethereumExplorerUrl = "https://etherscan.io/address/";

export const imxExplorerUrl = "https://immutascan.io/address/";

export const imxApiUrl = "https://api.x.immutable.com/v1";
