import { Box, Container, makeStyles } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { Balances } from "../components/balances";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { SubHeader } from "../components/subheader";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { ethereumConnector } from "../utils/connectors";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1500px",
    padding: 0,
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: -30,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  box: {
    width: "100%",
    padding: "10px",
  },
}));

export const Home = () => {
  const classes = useStyles();

  const { width } = useWindowDimensions();

  const { active, activate } =
    useWeb3React<ethers.providers.Web3Provider>("ethereum");
  if (!active) {
    activate(ethereumConnector);
  }

  return (
    <>
      <Header />
      {width > 1000 && <SubHeader />}

      <Container className={classes.container}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          mt={5}
        >
          {active && <Balances />}
          <Footer />
        </Box>
      </Container>
    </>
  );
};
