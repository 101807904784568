import { AbstractConnector } from "@web3-react/abstract-connector";
import { createWeb3ReactRoot } from "@web3-react/core";
import "./App.css";
import { Home } from "./pages/home";

const getLibrary = (provider?: any, connector?: AbstractConnector) => {
  if (provider) {
    return provider;
  }
  if (connector) {
    return connector.getProvider();
  }
  throw new Error("Invalid connector configuration");
};

const Web3ReactProvider = createWeb3ReactRoot("ethereum");

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <div className="App">
        <Home />
      </div>
    </Web3ReactProvider>
  );
}

export default App;
