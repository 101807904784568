import { Box, Grid, makeStyles } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useEthereumBurns } from "../hooks/useEthereumBurns";
import { useImx } from "../hooks/useImx";
import { useWeb3SWR } from "../hooks/useWeb3SWR";
import {
  addresses,
  ethereumExplorerUrl,
  gochainExplorerUrl,
  imxExplorerUrl,
} from "../utils/chainData";
import { BalanceCard, BalanceName } from "./balanceCard";

const useStyles = makeStyles((theme) => ({
  arrow: {
    width: "11px",
    height: "21px",
    [theme.breakpoints.down("sm")]: {
      width: 0,
      height: 0,
    },
  },
}));

export const Balances = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const gochainBurnTotal = "26381256826.7306980290600036";

  const { library } = useWeb3React<ethers.providers.Web3Provider>("ethereum");
  if (!library) {
    throw new Error("ethereum not configured");
  }

  const { data: ethereumReserveBalance } = useWeb3SWR<ethers.BigNumber>([
    "ethereum",
    addresses.omiToken,
    "balanceOf",
    addresses.ethereum.veveReserve,
  ]);

  const { data: ethereumStagingReserveBalance } = useWeb3SWR<ethers.BigNumber>([
    "ethereum",
    addresses.omiToken,
    "balanceOf",
    addresses.imx.veveReserve,
  ]);

  const { data: ethereumVaultBalance } = useWeb3SWR<ethers.BigNumber>([
    "ethereum",
    addresses.omiToken,
    "balanceOf",
    addresses.ethereum.veveVault,
  ]);

  const { data: ethereumStagingVaultBalance } = useWeb3SWR<ethers.BigNumber>([
      "ethereum",
      addresses.omiToken,
      "balanceOf",
      addresses.imx.veveVault,
  ]);

  const { data: ethereumBurnBalance } = useWeb3SWR<ethers.BigNumber>([
    "ethereum",
    addresses.omiToken,
    "balanceOf",
    addresses.ethereum.veveBurn,
  ]);

  const {
    reserveBalance: imxReserveBalance,
    vaultBalance: imxVaultBalance,
    burnBalance: imxBurnBalance,
  } = useImx();

  const totalBurned = useEthereumBurns();

  useEffect(() => {
    if (
      ethereumReserveBalance &&
      ethereumStagingReserveBalance &&
      ethereumVaultBalance &&
      ethereumStagingVaultBalance &&
      ethereumBurnBalance &&
      imxReserveBalance &&
      imxVaultBalance &&
      imxBurnBalance &&
      totalBurned
    ) {
      setLoading(false);
    }
  }, [
    ethereumReserveBalance,
    ethereumStagingReserveBalance,
    ethereumVaultBalance,
    ethereumStagingVaultBalance,
    ethereumBurnBalance,
    imxReserveBalance,
    imxVaultBalance,
    imxBurnBalance,
    totalBurned,
  ]);

  return (
    <Grid container justify="center">
      <Grid item sm={12} md={3}>
        <BalanceCard
          name="Reserve Wallet"
          balances={[
            {
              name: BalanceName.ETH,
              balance: loading
                ? "..."
                : ethers.utils.formatEther(
                    ethereumReserveBalance?.toString() ?? "0"
                  ),
              url: `${ethereumExplorerUrl}${addresses.ethereum.veveReserve.toLowerCase()}`,
            },
            {
                name: BalanceName.STAGING,
                balance: loading
                    ? "..."
                    : ethers.utils.formatEther(
                        ethereumStagingReserveBalance?.toString() ?? "0"
                    ),
                url: `${ethereumExplorerUrl}${addresses.imx.veveReserve.toLowerCase()}`,
            },
            {
              name: BalanceName.IMX,
              balance: loading
                ? "..."
                : ethers.utils.formatEther(
                    imxReserveBalance?.toString() ?? "0"
                  ),
              url: `${imxExplorerUrl}${addresses.imx.veveReserve.toLowerCase()}`,
            },
            {
              name: BalanceName.TOTAL,
              balance: loading
                ? "..."
                : new BigNumber(
                    ethers.utils.formatEther(
                      ethereumReserveBalance?.toString() ?? "0"
                    )
                  )
                    .plus(ethers.utils.formatEther(ethereumStagingReserveBalance ?? "0"))
                    .plus(ethers.utils.formatEther(imxReserveBalance ?? "0"))
                    .toString(),
            },
          ]}
        />
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={1}
        marginLeft={1}
        marginRight={1}
      >
        <img
          className={classes.arrow}
          src="/images/right-arrow.png"
          alt="right arrow"
        />
      </Box>
      <Grid item sm={12} md={3}>
        <BalanceCard
          name="Vault Wallet"
          balances={[
            {
              name: BalanceName.ETH,
              balance: loading
                ? "..."
                : ethers.utils.formatEther(
                    ethereumVaultBalance?.toString() ?? "0"
                  ),
              url: `${ethereumExplorerUrl}${addresses.ethereum.veveVault.toLowerCase()}`,
            },
            {
                name: BalanceName.STAGING,
                balance: loading
                    ? "..."
                    : ethers.utils.formatEther(
                        ethereumStagingVaultBalance?.toString() ?? "0"
                    ),
                url: `${ethereumExplorerUrl}${addresses.imx.veveVault.toLowerCase()}`,
            },
            {
              name: BalanceName.IMX,
              balance: loading
                ? "..."
                : ethers.utils.formatEther(imxVaultBalance?.toString() ?? "0"),
              url: `${imxExplorerUrl}${addresses.imx.veveVault.toLowerCase()}`,
            },
            {
              name: BalanceName.TOTAL,
              balance: loading
                ? "..."
                : new BigNumber(
                    ethers.utils.formatEther(
                      ethereumVaultBalance?.toString() ?? "0"
                    )
                  )
                    .plus(ethers.utils.formatEther(ethereumStagingVaultBalance ?? "0"))
                    .plus(ethers.utils.formatEther(imxVaultBalance ?? "0"))
                    .toString(),
            },
          ]}
        />
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={1}
        marginLeft={1}
        marginRight={1}
      >
        <img
          className={classes.arrow}
          src="/images/right-arrow.png"
          alt="right arrow"
        />
      </Box>
      <Grid item sm={12} md={3}>
        <BalanceCard
          name="Token Burns*"
          balances={[
            {
              name: BalanceName.GO,
              balance: loading ? "..." : gochainBurnTotal,
              url: `${gochainExplorerUrl}${addresses.imx.veveBurn.toLowerCase()}`,
            },
            {
              name: BalanceName.ETH,
              balance: loading ? "..." : totalBurned ?? "0",
              url: `${ethereumExplorerUrl}${addresses.imx.veveBurn.toLowerCase()}`, // L1 OMI is burned from the same IMX address
            },
            {
              name: BalanceName.IMX,
              balance: loading
                ? "..."
                : ethers.utils.formatEther(imxBurnBalance?.toString() ?? "0"),
              url: `${imxExplorerUrl}${addresses.imx.veveBurn.toLowerCase()}`,
            },
            {
              name: BalanceName.TOTAL,
              balance: loading
                ? "..."
                : new BigNumber(gochainBurnTotal)
                    .plus(totalBurned ?? "0")
                    .plus(ethers.utils.formatEther(imxBurnBalance ?? "0"))
                    .toString(),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};
